import { Pipe, PipeTransform } from '@angular/core';
import Typograf from 'typograf';

@Pipe({
  name: 'typograf',
  standalone: true,
})
export class TypografPipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) return '';
    const typograf = new Typograf({ locale: ['ru', 'en-US'] });
    return typograf.execute(value);
  }
}
