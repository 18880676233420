import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[expocabClickOutside]',
  standalone: true,
})
export class ClickOutsideDirective {
  @Output() clickOutsideValue = new EventEmitter<HTMLElement>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: HTMLElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);

    if (!clickedInside) {
      this.clickOutsideValue.emit(targetElement);
    }
  }
}
