import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'svgPath',
  standalone: true,
})
export class SvgPathPipe implements PipeTransform {
  transform(value: string): string {
    return `/assets/icons/${value}.svg`;
  }
}
